.grayout-active {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0;
}

.grayout-inactive {
  display: none;
}