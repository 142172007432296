.section-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-family: bebas_neueregular;
  font-size: 108px;
  -webkit-text-stroke: 3px var(--primary-color);
  text-shadow: 0px 0px 20px rgba(40, 40, 40, 0.75);
}

@media screen and (max-width: 1200px) {
  .section-header {
    font-size: 92px;
  }
}

@media screen and (max-width: 750px) {
  .section-header {
    height: 250px;
    font-size: 80px;
    -webkit-text-stroke: 2px var(--primary-color);
  }
}

@media screen and (max-width: 500px) {
  .section-header {
    height: 200px;
    font-size: 60px;
    -webkit-text-stroke: 1.5px var(--primary-color);
  }
}