#navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  width: 100%;
  z-index: 1000;
}

#navbar-without-sidenav {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: var(--primary-color);
  z-index: 1;
}

.text-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-size: 40px;
  font-weight: bold;
}

.nav-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 200px);
}

.nav-items {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
  padding: 50px;
}

.nav-item {
  padding: 25px;
  width: 70.7px;
}

@media screen and (max-width: 1000px) {
  .nav-items {
    padding: 30px;
  }
}

@media screen and (max-width: 750px) {
  .nav-items {
    padding: 20px;
  }

  .nav-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 675px) {
  .nav-items {
    padding: 20px;
  }

  .nav-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 600px) {
  #navbar {
    position: fixed;
    background: var(--primary-color);
    box-shadow: 0px 0px 20px rgba(40, 40, 40, 0.75);
  }

  #navbar-without-sidenav {
    height: 0;
    width: auto;
  }

  .navbar-content {
    display: none;
  }

  .nav-items {
    display: none;
  }

  .text-logo {
    width: auto;
    font-size: 30px;
    color: black;
    text-shadow: 0px 0px 20px var(--primary-color__dark);
  }
}

.active {
  border-bottom: 5px solid var(--primary-color);
}

.nav-item:hover {
  cursor: pointer;
  color: var(--primary-color__light);
}

.nav-item:active {
  color: var(--primary-color__dark);
}