#sidenav {
  display: flex;
  flex-direction: row;
}

#sidenav-content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 200px;
  background: black;
  z-index: 10;
  box-shadow: 0px 0px 30px rgba(40, 40, 40, 0.75);
}

#name-icon {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  font-family: bebas_neueregular;
  color: black;
  font-size: 50px;
  font-weight: bold;
  -webkit-text-stroke: 1px var(--primary-color);
  text-shadow: 0px 0px 10px var(--primary-color);
}

.sidenav-list {
  height: 25%;
	display: flex;
	flex-direction: column;
  justify-content: space-around;
}

.sidenav-button-wrapper {
  display: flex;
  align-items: center;
  width: 200px;
  height: 75px;
  text-decoration: none;
  color: var(--primary-color);
}

.sidenav-button-wrapper:hover {
  background: rgba(40, 40, 40, 0.5);
}

.sidenav-button {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
  padding: 25px !important;
}

.sidenav-button-text {
  font-family: bebas_neueregular;
  font-size: 24px;
  text-shadow: 0px 0px 2px var(--primary-color__dark);
}

@media screen and (min-width: 600px) {
  #sidenav {
    display: none;
  }
}
