#about {
  width: 100%
}

#about-frontpage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 100px);
}

@media screen and (max-width: 600px) {
  #about-frontpage {
    height: 100vh;
  }
}

.about-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
}

.about-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.name {
  display: flex;
  font-family: bebas_neueregular;
  font-size: 136px;
  -webkit-text-stroke: 3px var(--primary-color);
  text-shadow: 0px 0px 20px rgba(40, 40, 40, 0.75);
}

.tagline {
  display: flex;
  justify-content: flex-start;
  font-family: bebas_neueregular;
  font-weight: bold;
  font-size: 36px;
  -webkit-text-stroke: 0.001px var(--primary-color);
  text-shadow: 0px 0px 5px rgba(40, 40, 40, 0.75);
}

@media screen and (max-width: 1300px) {
  .about-text-container {
    max-width: 500px;
  }

  .name {
    font-size: 108px;
    -webkit-text-stroke: 2px var(--primary-color);
  }

  .tagline {
    font-size: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .about-text-container {
    max-width: 400px;
  }

  .name {
    font-size: 72px;
    -webkit-text-stroke: 2px var(--primary-color);
  }

  .tagline {
    font-size: 24px;
  }
}

@media screen and (max-width: 700px) {
  .about-text-container {
    max-width: 300px;
  }

  .name {
    font-size: 48px;
    -webkit-text-stroke: 1px var(--primary-color);
  }

  .tagline {
    font-size: 18px;
    text-shadow: 0px 0px 3px rgba(40, 40, 40, 0.75);
  }
}

@media screen and (max-width: 500px) {
  .about-text-container {
    max-width: 200px;
  }

  .name {
    font-size: 36px;
  }

  .tagline {
    font-size: 12px;
    text-shadow: 0px 0px 1px rgba(40, 40, 40, 0.75);
  }
}

@media screen and (max-width: 350px) {
  .about-text-container {
    max-width: 150px;
  }

  .name {
    font-size: 32px;
  }

  .tagline {
    font-size: 10px;
  }
}

.about-blurb {
  display: flex;
  flex-direction: row;
  /* height: -webkit-fill-available; */
}

.about-blurb__written {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.about-blurb__written__title {
  font-size: 48px;
  font-family: bebas_neueregular;
}

@media screen and (max-width: 400px) {
  .about-blurb__written__title {
    font-size: 28px;
  }
}

.about-icons {
  display: flex;
  justify-content: space-between !important;
  width: 160px;
  margin-bottom: 20px;
}

.about-icon {
  color: black !important;
  width: 40px !important;
  height: 40px !important;
}

@media screen and (max-width: 600px) {
  .about-icons {
    display: flex;
    justify-content: space-between !important;
    width: 120px;
    margin-bottom: 20px;
  }
  
  .about-icon {
    color: black !important;
    width: 30px !important;
    height: 30px !important;
  }
}

.resume-download__button {
  background-color: var(--primary-color);
  text-decoration: none;
  color: white;
  padding: 12px;
  border-radius: 50px;
}

.resume-download__button:hover {
  color: var(--primary-color__dark);
}

.about-blurb__written__text {
  text-align: left;
}

.timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}