:root {
  --primary-color: #E23F1C;
  --primary-color__light: #FFA895;
  --primary-color__dark: #80230F;
  --hover-gray: #6C6361;
}

html, body, #root {
  width: 100%;
  height: 100%;
  background: url('../assets//images/Amazon_Photo.jpg') no-repeat center center fixed;
  background-size: cover
}

h1, h2, h3 {
  margin: 0px;
  font-weight: normal;
}

#grayout {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0;
  z-index: 0;
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 30px;
  box-shadow: 0px 0px 20px rgba(40, 40, 40, 0.75);
}

.section-content {
  width: 80%;
}

.under-construction {
  padding: 10px;
}

.flourish {
  font-family: bebas_neueregular;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .flourish {
    font-size: 24px;
  }
}

.about-link {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
}

.about-link:hover {
  color: var(--primary-color__light);
}

.about-link:active {
  color: var(--primary-color__dark);
}