#blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 900px) {
  #blog-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  #blog-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.blog-card {
  width: calc(100%-20px);
  height: 300px;
  box-shadow: 0px 0px 10px rgba(40, 40, 40, 0.75);
  margin: 20px;
}

.blog-image {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.blog-content {
  padding: 15px;
  text-align: left;
}

.blog-title {
  font-weight: bold;
  font-size: 14pt;
  text-decoration: none;
  color: black;
}

.blog-title:hover {
  color: var(--hover-gray);
}

.blog-info {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  font-weight: bold;
}

.blog-date {
  color: var(--primary-color);
}