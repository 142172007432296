#contact {
  margin-bottom: 100px;
}

.contact-body {
  background-color: var(--primary-color);
  box-shadow: 0px 0px 20px rgba(40, 40, 40, 0.75);
  padding-top: 50px;
}

.social-media-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-media-icon {
  width: 82px !important;
  height: 82px !important;
  background-color: black;
  color: var(--primary-color) !important;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .contact-body {
    padding-top: 30px;
  }

  .social-media-icon {
    width: 40px !important;
    height: 40px !important;
  }
}

.calendly-signup {
  margin-top: 15px;
  padding-bottom: 25px;
}

.contact-link {
  color: black;
}